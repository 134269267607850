// <div data-controller="notes--versions">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = ["list"];

  get repositoryKey() {
    return `versions-${this.data.get("post-ext-id")}`;
  }

  get currentRepository() {
    return JSON.parse(localStorage.getItem(this.repositoryKey)) || [];
  }

  get editorJsController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("editorJsForm"), "components--editor-js")
  }

  listModalController(index) {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("modal" + index), "components--modal")
  }

  listEditorHtmlController(index) {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("editorHtml" + index), "components--editor-html")
  }

  recoverRepositoryData(editorData, index) {
    if (confirm("작성중이던 데이터를 히스토리에 넣고, 이 데이터로 복구 하시겠어요?")) {
      this.editorJsController.clearAndRender(editorData);
      this.listModalController(index).hideModal();
    }
  }

  renderEditorDataArticle(articleTarget, editorData, index) {
    const editorHtml = this.listEditorHtmlController(index).parseEditorData(editorData);
    articleTarget.innerHTML = editorHtml;
  }

  listTemplate(editorData, index) {
    const savedAtString = new Date(editorData.time).toLocaleTimeString();
    const listUnit = document.createElement("li");

    listUnit.className = "px-6 py-5 relative";
    listUnit.innerHTML = `<div class="group flex justify-between items-center space-x-2">
                <div class="-m-1 p-1 block">
                  <span class="absolute inset-0 group-hover:bg-slate-50 dark:bg-slate-800"></span>
                  <div class="flex-1 flex items-center min-w-0 relative">
                    <div class="truncate">
                      <div class="text-sm leading-5 font-medium text-slate-900 dark:text-slate-200 truncate">${savedAtString}</div>
                      <div class="text-sm leading-5 text-slate-600 dark:text-slate-300 truncate">${editorData.blocks.length} 블럭 저장 됨</div>
                    </div>
                  </div>
                </div>
                <div id="modal${index}"
                     data-controller="components--modal">
                  <button type="button"
                          id="modalButton${index}"
                          data-action="click->components--modal#showModal"
                          class="group relative w-8 h-8 inline-flex items-center justify-center focus:outline-none"
                          aria-haspopup="true" x-bind:aria-expanded="open">
                        <span class="flex items-center justify-center h-full w-full rounded-full group-focus:bg-gray-200 transition ease-in-out duration-150">
                          <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-gray-400 group-hover:text-slate-600 dark:text-slate-300 group-focus:text-slate-600 dark:text-slate-300 transition ease-in-out duration-150" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <polyline points="16 4 20 4 20 8" />
                            <line x1="14" y1="10" x2="20" y2="4" />
                            <polyline points="8 20 4 20 4 16" />
                            <line x1="4" y1="20" x2="10" y2="14" />
                            <polyline points="16 20 20 20 20 16" />
                            <line x1="14" y1="14" x2="20" y2="20" />
                            <polyline points="8 4 4 4 4 8" />
                            <line x1="4" y1="4" x2="10" y2="10" />
                          </svg>
                        </span>
                  </button>
                  <div data-components--modal-target="modal" style="display: none;"
                       class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-30">
                    <div data-action="click->components--modal#hideModal"
                         class="fixed inset-0 transition-opacity">
                      <div class="absolute inset-0 bg-stone-300 dark:bg-stone-700 opacity-75 z-30"></div>
                    </div>
                    <div class="bg-white dark:bg-stone-900 rounded-lg px-4 pt-5 pb-4 overflow-auto shadow-xl transform transition-all max-w-sm lg:max-w-3xl w-full mx-auto sm:p-6"
                         role="dialog"
                         aria-modal="true"
                         aria-labelledby="modal-headline">
                         <div id="article${index}" class="overflow-scroll" style="max-height: 75vh;"></div>
                         <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                          <span id="button${index}"
                                class="flex w-full rounded-md shadow-sm sm:col-start-2"></span>
                          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                            <button type="button"
                                   data-action="click->components--modal#hideModal" 
                                   class="w-full text-center maily-white-button">
                              취소
                            </button>
                          </span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>`;

    const applyButton = document.createElement("button");
    applyButton.type = "button";
    applyButton.className = "inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-333 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-900 focus:outline-none focus:border-green-300 focus:ring-green-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5";
    applyButton.innerText = "복원하기";
    applyButton.addEventListener("click", () => this.recoverRepositoryData(editorData, index))
    listUnit.querySelector("#button" + index).appendChild(applyButton);

    const listArticle = document.createElement("article");
    listArticle.className = "w-full max-w-full bg-white dark:bg-stone-900 mb-4 lg:mb-8 prose dark:prose-invert lg:prose-lg";
    listArticle.id = `editorHtml${index}`;
    listArticle.setAttribute("data-controller", "components--editor-html");
    listArticle.setAttribute("data-components--editor-html-target", "article");
    listUnit.querySelector("#article" + index).appendChild(listArticle);

    listUnit.querySelector("#modalButton" + index).addEventListener("click", (e) => {
      const articleTarget = document.getElementById("editorHtml" + index);
      this.renderEditorDataArticle(articleTarget, this.currentRepository[index], index)
    });

    return listUnit;
  }

  reloadList() {
    while(this.listTarget.firstChild) {
      this.listTarget.removeChild(this.listTarget.firstChild);
    }

    this.currentRepository.forEach((editorData, index) => {
      this.listTarget.prepend(this.listTemplate(editorData, index));
    });
  }

  connect() {
    this.reloadList();
  }
}