// <div data-controller="notes--edit-publishing">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "postingTypeOptions", "priceOptions", "previewDataOption" ];
  static classes = [ "" ]
  static values = {};

  initialize() {

  }

  onChangePostingType() {
    this.priceOptionsTarget.classList.toggle("hidden", this.checkedPostingType !== "product")
    this.previewDataOptionTarget.classList.toggle("hidden", (this.checkedPostingType !== "product" && this.checkedPostingType !== "paid_membership"))
  }

  get checkedPostingType() {
    const postingOptions = this.postingTypeOptionsTarget.options;
    return postingOptions[postingOptions.selectedIndex].value;
  }

  connect() {
    this.onChangePostingType();
  }

  disconnect() {

  }
}