// <div data-controller="business--plan-with-credit">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import * as helpers from "@/utils/helpers";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [
      "planTypeInput", "creditPlanSelect", "paymentCycleInput",
      "priceDescription", "extraCreditDescription",
      "cleanUpAgreement",
      "cleanUpAgreementCheckBox"
  ];
  static classes = [ "" ]
  static values = {
    planTypeCreditPlanOptions: Object,
    creditPlanMapping: Object,
    yearlyRate: Number,
    currentCreditPlanId: Number,
    persistedPlanType: String,
    isPersisted: Boolean,
    subscriptionPlanType: String,
  };

  initialize() {

  }

  get currentPlanType() {
    let checkedPlan;
    this.planTypeInputTargets.forEach((target) => {
      if (target.checked) {
        checkedPlan = target.value;
      }
    });
    return checkedPlan;
  }

  get currentPaymentCycle() {
    let checkedPaymentCycle;
    this.paymentCycleInputTargets.forEach((target) => {
      if (target.checked) {
        checkedPaymentCycle = target.value;
      }
    });
    return checkedPaymentCycle;
  }

  get currentCreditPlan() {
    const creditPlanOptions = this.creditPlanSelectTarget.options;
    const selectedCreditPlanId = creditPlanOptions[creditPlanOptions.selectedIndex].value;
    return this.creditPlanMappingValue[selectedCreditPlanId];
  }

  updateCreditPlanSelectOptions(options) {
    // clear previous options
    while (this.creditPlanSelectTarget.options.length > 0) {
      this.creditPlanSelectTarget.remove(0);
    }

    let hasMatchingDefaultOption;
    options.forEach((option) => {
      const newOption = document.createElement('option');
      const optionText = document.createTextNode(option.text);
      newOption.appendChild(optionText);
      newOption.setAttribute('value', option.value);
      if (this.currentCreditPlanIdValue === option.value) {
        hasMatchingDefaultOption = true;
      }
      this.creditPlanSelectTarget.appendChild(newOption);
    })

    if (hasMatchingDefaultOption) {
      this.creditPlanSelectTarget.value = this.currentCreditPlanIdValue;
    }
  }

  warnPlanTypeDowngrade(show) {
    if (this.hasCleanUpAgreementTarget) {
      this.cleanUpAgreementTarget.classList.toggle("hidden", !show)
      this.cleanUpAgreementCheckBoxTarget.required = show;
    }
  }

  afterPlanTypeSelected() {
    if (this.currentPlanType === "basic") {
      this.updateCreditPlanSelectOptions(this.planTypeCreditPlanOptionsValue.basic);
      this.warnPlanTypeDowngrade(this.isPersistedValue && (this.persistedPlanTypeValue === "branding") && (this.subscriptionPlanTypeValue !== "basic"));
    } else if (this.currentPlanType === "branding") {
      this.updateCreditPlanSelectOptions(this.planTypeCreditPlanOptionsValue.branding);
      this.warnPlanTypeDowngrade(false);
    }
    this.calculatePrice();
  }

  afterPaymentCycleSelected() {
    this.calculatePrice();
  }

  afterCreditPlanSelected() {
    this.calculatePrice();
  }

  paymentCycleAvailability(unitPrice) {
    this.paymentCycleInputTargets.forEach((target) => {
      target.disabled = (unitPrice === 0 && (target.value !== "monthly"));
      const targetLabel = document.querySelector(`label[for=${target.id}`)
      targetLabel.classList.toggle("disabled", target.disabled)

      if (unitPrice === 0 && (target.value === "monthly")) {
        target.checked = true;
      }
    })
  }

  calculatePrice() {
    if (this.currentCreditPlan === undefined || this.currentPaymentCycle === undefined) {
      this.priceDescriptionTarget.innerHTML = "-";
      return;
    }

    let label;
    const unitPrice = this.currentCreditPlan.price;
    const credits = this.currentCreditPlan.credits;

    this.paymentCycleAvailability(unitPrice);

    if (this.currentPaymentCycle === "yearly") {
      const price = this.yearlyRateValue * unitPrice
      const discountPrice = (12 - this.yearlyRateValue) * unitPrice
      const discountLabel = `<span class="italic text-xs text-red-600 dark:text-red-300">${helpers.krw(discountPrice)} 할인 적용</span>`;
      label = `연 ${helpers.numberWithDelimiter(credits * 12)}건 (${helpers.krw(price)}) ${discountLabel}`
    } else if (this.currentPaymentCycle === "monthly") {
      const price = unitPrice
      label = `월 ${helpers.numberWithDelimiter(credits)}건 (${helpers.krw(price)})`
    }

    this.extraCreditDescriptionTarget.innerText = `이후 초과되는 발송량에 대해서는 건당 ${helpers.krw(this.currentCreditPlan.credit_price)} 으로 결제 됩니다.`;

    this.priceDescriptionTarget.innerHTML = label;
  }

  connect() {
    this.afterPlanTypeSelected();
  }

  disconnect() {

  }
}
